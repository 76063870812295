import {getFullMonthText} from '@wix/wix-events-commons-statics/dist/date/date'
import {withNamespaces, WithNamespaces} from 'react-i18next'
import {getFullLocale} from '../../../../../commons/selectors/environment'
import {addCalendarMonth, resetCalendar, subCalendarMonth} from '../../../../actions/calendar-layout'
import {getReferenceDate} from '../../../../selectors/calendar-layout'
import {AppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/connect'
import {MobileMonthlyCalendar as MobileMonthlyCalendarPresentation} from './mobile-monthly-calendar'

export interface MobileMonthlyCalendarOwnProps {}

export interface MobileMonthlyCalendarRuntimeProps {
  referenceDate: Date
  fullMonthText: string
  resetCalendar: typeof resetCalendar
  addCalendarMonth: typeof addCalendarMonth
  subCalendarMonth: typeof subCalendarMonth
}

export interface MobileMonthlyCalendarProps
  extends MobileMonthlyCalendarRuntimeProps,
    MobileMonthlyCalendarOwnProps,
    WithNamespaces {}

const mapRuntime = ({actions, state}: AppProps): MobileMonthlyCalendarRuntimeProps => ({
  referenceDate: getReferenceDate(state),
  fullMonthText: getFullMonthText(getReferenceDate(state), getFullLocale(state)),
  resetCalendar: actions.resetCalendar,
  addCalendarMonth: actions.addCalendarMonth,
  subCalendarMonth: actions.subCalendarMonth,
})

export const MobileMonthlyCalendar = connect<MobileMonthlyCalendarOwnProps, MobileMonthlyCalendarRuntimeProps>(
  mapRuntime,
)(withNamespaces()(MobileMonthlyCalendarPresentation))
