import {getElementHeight, getElementWidth, setElementStyle} from '../../../commons/utils/dom'
import {resizeComponent, setHeight} from '../../../commons/utils/wix-sdk'
import {DH} from '../../utils/data-hooks'

export interface CardsConfig {
  fullWidth: boolean
  showImage: boolean
  hideAdditionalComponents: boolean
  columns: number
  cardMargins: number
  cardBorderWidth: number
  imageRatio: string
  hoverClass: string
  noTransitionsClass: string
  site: boolean
  editor: boolean
}

interface CardStyles {
  cardWidth?: string
  cardHeight?: string
  cardMinHeight?: string
  imageHeight?: string
  contentHeight?: string
  contentPadding?: string
}

const MIN_CARD_WIDTH: number = 264
const MAX_CARD_WIDTH: number = 500
const CONTENT_MARGIN: number = 42
const RIBBON_MARGINS: number = 44 //margin top + margin bottom
const CONTENT_MARGIN_WITH_MEMBERS: number = 30
const CONTENT_PADDING: number = 0

const CARDS_TITLE_SELECTOR: string = `[data-hook="${DH.listTitle}"]`
const CARDS_SELECTOR: string = `[data-hook="${DH.cards}"]`
const IMAGE_SELECTOR: string = '[data-hook="image"]'
const CONTENT_SELECTOR: string = '[data-hook="content"]'
const TITLE_SELECTOR: string = '[data-hook="title"]'
const SHORT_DATE_LOCATION_SELECTOR: string = '[data-hook="short-date-location"]'
const DETAILS_SELECTOR: string = '[data-hook="details"]'
const MEMBERS_SECTION_SELECTOR: string = '[data-hook="members"]'
const BUTTON_SECTION_SELECTOR: string = '[data-hook="button-section"]'
const RIBBON_SELECTOR: string = '[data-hook="ribbon"]'

export class Utils {
  container: HTMLElement
  cards: HTMLElement[]
  cardHeight: number

  constructor(private hasMembers: boolean) {}

  setContainer = (element: HTMLElement) => (this.container = element)

  addCard = (element: HTMLElement) => element && this.cards.push(element)

  resetCards = () => (this.cards = [])

  setStyles = (config: CardsConfig) => {
    setElementStyle(this.container.querySelector(CARDS_SELECTOR), 'padding', `${config.cardMargins / 2}px`)
    setElementStyle(
      this.container.querySelector(CARDS_TITLE_SELECTOR),
      'paddingBottom',
      `calc(72px - .3em - ${config.cardMargins}px)`,
    )
    this.cards.forEach((card: HTMLElement) => setElementStyle(card, 'margin', `${config.cardMargins / 2}px`))
  }

  calculateCardsStyles = (config: CardsConfig) => {
    if (!this.getCardsHeight()) {
      return
    }

    const cardWidth = this.getCardWidth(config)
    const cardMinHeight = config.showImage ? 0 : cardWidth

    config.site && this.removeTransitions(config)

    this.setCardsWidth(cardWidth, config)

    this.cards.forEach((card: HTMLElement) => {
      const imageHeight =
        config.showImage && !this.isCardHovered(card, config) ? this.getImageHeight(cardWidth, config.imageRatio) : 0

      this.setCardStyles(card, {
        cardWidth: `${cardWidth}px`,
        cardHeight:
          this.cards.length === 1
            ? this.getSingleCardHeight(card, config)
            : this.isCardHovered(card, config)
            ? `0`
            : `auto`,
        cardMinHeight: `${cardMinHeight}px`,
        imageHeight: `${imageHeight}px`,
        contentHeight: `calc(100% - ${imageHeight + this.getBottomSectionHeight(card) + this.contentMargin(card)}px)`,
        contentPadding: `${CONTENT_PADDING}px`,
      })
    })

    const maxHeight = (this.cardHeight = this.findMaxCardHeight(config))

    this.cards.forEach((card: HTMLElement) => {
      this.setCardStyles(card, {
        cardHeight: `${maxHeight}px`,
        contentPadding: `${
          config.showImage && !this.isCardHovered(card, config)
            ? CONTENT_PADDING
            : this.getContentPadding(card, maxHeight - 2 * config.cardBorderWidth, this.isCardHovered(card, config))
        }px`,
      })

      this.setContentOverflow(card, false)
    })

    config.site && setTimeout(() => this.addTransitions(config))
  }

  addCardsListeners = (getConfig: () => CardsConfig) => {
    this.cards.forEach((card: HTMLElement) => {
      if (!card.onmouseover) {
        card.onmouseover = () => {
          const config = getConfig()
          !config.editor && this.hoverCard(card, config)
        }
      }
      if (!card.onmouseleave) {
        card.onmouseleave = () => this.leaveCard(card, getConfig())
      }
    })
  }

  leaveCards = (config: CardsConfig) =>
    this.cards.forEach(card => this.isCardHovered(card, config) && this.leaveCard(card, config))

  hoverFirstCard = (config: CardsConfig) =>
    !this.isCardHovered(this.cards[0], config) && this.hoverCard(this.cards[0], config)

  hoverCard = (card: HTMLElement, config: CardsConfig) => {
    if (!config.hideAdditionalComponents) {
      this.cardHeight = card.getBoundingClientRect().height

      this.setComponentsWidth(card)
      card.classList.add(config.hoverClass)

      this.setCardStyles(card, {
        cardHeight: `${this.cardHeight}px`,
        imageHeight: `${0}px`,
        contentHeight: `${this.cardHeight -
          2 * config.cardBorderWidth -
          this.getBottomSectionHeight(card) -
          this.contentMargin(card)}px`,
        contentPadding: `${this.getContentPadding(card, this.cardHeight - 2 * config.cardBorderWidth, true)}px`,
      })
    }
  }

  leaveCard = (card: HTMLElement, config: CardsConfig) => {
    const imageHeight = config.showImage ? this.getImageHeight(this.getCardWidth(config), config.imageRatio) : 0

    card.querySelector(CONTENT_SELECTOR).scrollTop = 0
    this.setContentOverflow(card, false)
    this.setComponentsWidth(card, 'auto')
    card.classList.remove(config.hoverClass)

    this.setCardStyles(card, {
      cardHeight: `${this.cardHeight}px`,
      imageHeight: `${imageHeight}px`,
      contentHeight: `calc(100% - ${imageHeight + this.getBottomSectionHeight(card) + this.contentMargin(card)}px)`,
      contentPadding: `${
        config.showImage
          ? CONTENT_PADDING
          : this.getContentPadding(card, this.cardHeight - 2 * config.cardBorderWidth, false)
      }px`,
    })
  }

  resizeContainer = (config: CardsConfig) => {
    const containerWidth = this.getContainerWidth()
    const cardsMinWidth = this.getMinCardsWidth(config)

    if (config.editor && containerWidth && containerWidth < cardsMinWidth) {
      resizeComponent({width: cardsMinWidth})
        .catch(() => null)
        .then(() => this.setContainerHeight())
    } else {
      this.setContainerHeight()
    }
  }

  contentMargin = (card: HTMLElement) => {
    const ribbon = this.getRibbon(card)
    return ribbon
      ? ribbon.offsetHeight + RIBBON_MARGINS
      : this.hasMembers
      ? CONTENT_MARGIN_WITH_MEMBERS
      : CONTENT_MARGIN
  }

  getRibbon = (card: HTMLElement) => card.querySelector<HTMLElement>(RIBBON_SELECTOR)

  setContainerHeight = () => setHeight(this.getCardsHeight() + this.getCardsTitleHeight())

  addTransitions = (config: CardsConfig) => this.container.classList.remove(config.noTransitionsClass)

  removeTransitions = (config: CardsConfig) => this.container.classList.add(config.noTransitionsClass)

  isCardHovered = (card: HTMLElement, config: CardsConfig) => card.classList.contains(config.hoverClass)

  getCardWidth = (config: CardsConfig) => {
    const columns = this.getColumnsCount(config)
    const spaces = (columns + 1) * config.cardMargins
    const containerWidth = this.getContainerWidth()
    const width = Math.min(Math.max(MIN_CARD_WIDTH, (containerWidth - spaces) / columns), MAX_CARD_WIDTH)

    if (config.site && config.fullWidth && columns * width + spaces > containerWidth) {
      config.columns -= 1
      return this.getCardWidth(config)
    }

    return width
  }

  getCardHeight = (card: HTMLElement, config: CardsConfig) =>
    !this.isCardHovered(card, config) ? card.getBoundingClientRect().height : this.getHoveredCardHeight(card, config)

  getSingleCardHeight = (card: HTMLElement, config: CardsConfig) =>
    !this.isCardHovered(card, config) ? `auto` : `${this.getHoveredCardHeight(card, config)}px`

  getHoveredCardHeight = (card: HTMLElement, config: CardsConfig) => {
    this.leaveCard(card, config)
    setElementStyle(card, 'height', 'auto')
    this.hoverCard(card, config)
    return this.cardHeight
  }

  getImageHeight = (width: number, ratio: string) =>
    (width / parseInt(ratio.split(':')[0], 10)) * parseInt(ratio.split(':')[1], 10)

  getContentPadding = (card: HTMLElement, height: number, includeDetails: boolean) => {
    const detailsHeight =
      this.getTitleMargin(card) +
      this.getTitleHeight(card) +
      (includeDetails ? this.getDetailsHeight(card) : this.getShortDateLocationHeight(card))
    const padding = (height - detailsHeight - this.getBottomSectionHeight(card) - this.contentMargin(card)) / 2

    this.setContentOverflow(card, padding < CONTENT_PADDING)

    return padding > CONTENT_PADDING ? padding : CONTENT_PADDING
  }

  getContainerWidth = () => this.container.getBoundingClientRect().width

  getColumnsCount = (config: CardsConfig) => Math.min(config.fullWidth ? 5 : 3, config.columns, this.cards.length)

  getMinCardsWidth = (config: CardsConfig) => {
    const columns = this.getColumnsCount(config)

    return columns * MIN_CARD_WIDTH + (columns + 1) * config.cardMargins
  }

  getCardsHeight = () => getElementHeight(CARDS_SELECTOR, this.container)

  getCardsTitleHeight = () => getElementHeight(CARDS_TITLE_SELECTOR, this.container)

  getTitleMargin = (card: HTMLElement) =>
    Math.max(parseFloat(getComputedStyle(card.querySelector(TITLE_SELECTOR)).marginBottom), 12)

  getTitleHeight = (card: HTMLElement) => getElementHeight(TITLE_SELECTOR, card)

  getDetailsHeight = (card: HTMLElement) => getElementHeight(DETAILS_SELECTOR, card)

  getShortDateLocationHeight = (card: HTMLElement) => getElementHeight(SHORT_DATE_LOCATION_SELECTOR, card)

  getMembersSectionHeight = (card: HTMLElement) => getElementHeight(MEMBERS_SECTION_SELECTOR, card)

  getButtonSectionHeight = (card: HTMLElement) => getElementHeight(BUTTON_SECTION_SELECTOR, card)

  getBottomSectionHeight = (card: HTMLElement) => this.getButtonSectionHeight(card) + this.getMembersSectionHeight(card)

  setCardsWidth = (cardWidth: number, config: CardsConfig) => {
    const columns = this.getColumnsCount(config)
    const width = columns * cardWidth + (columns + 1) * config.cardMargins
    setElementStyle(this.container.querySelector(CARDS_SELECTOR), 'width', `${width}px`)
  }

  setCardStyles = (card: HTMLElement, styles: CardStyles) => {
    setElementStyle(card, 'width', styles.cardWidth)
    setElementStyle(card, 'height', styles.cardHeight)
    setElementStyle(card, 'minHeight', styles.cardMinHeight)
    setElementStyle(card.querySelector(IMAGE_SELECTOR), 'height', styles.imageHeight)
    setElementStyle(card.querySelector(CONTENT_SELECTOR), 'height', styles.contentHeight)
    setElementStyle(card.querySelector(CONTENT_SELECTOR), 'paddingTop', styles.contentPadding)
  }

  setContentOverflow = (card: HTMLElement, overflows: boolean) =>
    setElementStyle(card.querySelector(CONTENT_SELECTOR), 'overflowY', overflows ? 'scroll' : 'hidden')

  setComponentsWidth = (card: HTMLElement, width?: string) => {
    width = width || `${getElementWidth(TITLE_SELECTOR, card)}px`
    setElementStyle(card.querySelector(TITLE_SELECTOR), 'width', width)
    setElementStyle(card.querySelector(SHORT_DATE_LOCATION_SELECTOR), 'width', width)
    setElementStyle(card.querySelector(DETAILS_SELECTOR), 'width', width)
  }

  findMaxCardHeight = (config: CardsConfig) =>
    this.cards.reduce((height: number, card: HTMLElement) => Math.max(height, this.getCardHeight(card, config)), 0)
}
