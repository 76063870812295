import {withNamespaces, WithNamespaces} from 'react-i18next'
import {getRibbonText} from '../../selectors/events'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {Ribbon as RibbonPresentation} from './ribbon'

export interface RibbonProps extends RibbonOwnProps, RibbonRuntimeProps, WithNamespaces {}

export interface RibbonOwnProps {
  event: ExtendedEvent
}

export interface RibbonRuntimeProps {
  text: string
}

const mapRuntime = ({state}: AppProps, {event}: RibbonOwnProps): RibbonRuntimeProps => ({
  text: getRibbonText(state, event),
})

export const Ribbon = connect<RibbonOwnProps, RibbonRuntimeProps>(mapRuntime)(withNamespaces()(RibbonPresentation))
