import {IWixAPI} from '@wix/native-components-infra/dist/src/types/types'
import {WEEKDAY} from '@wix/wix-events-commons-statics/dist/date/date'
import {setBaseEnvironment} from '../../commons/actions/environment'
import {updateSiteSettings} from '../../commons/actions/site-settings'
import {CommonState} from '../../commons/types/state'
import {
  addCalendarMonth,
  closeMonthlyCalendarEvent,
  closeMonthlyCalendarPopup,
  openMonthlyCalendarEvent,
  openMonthlyCalendarPopup,
  resetCalendar,
  subCalendarMonth,
} from '../actions/calendar-layout'
import {updateComponent, updateComponentDraft, updateStyleParams} from '../actions/component'
import {createEvent} from '../actions/event'
import {closeListLayoutItems, openListLayoutItem, widgetLoaded} from '../actions/layout'
import {openMembersModal} from '../actions/members'
import {navigateToPage} from '../actions/navigate-to-page'
import {updateSettings, updateStyle} from '../actions/sdk'

export interface Actions {
  addCalendarMonth: typeof addCalendarMonth
  resetCalendar: typeof resetCalendar
  subCalendarMonth: typeof subCalendarMonth
  createEvent: typeof createEvent
  updateComponent: typeof updateComponent
  updateComponentDraft: typeof updateComponentDraft
  updateSiteSettings: typeof updateSiteSettings
  navigateToPage: typeof navigateToPage
  updateStyleParams: typeof updateStyleParams
  updateStyle: typeof updateStyle
  updateSettings: typeof updateSettings
  setBaseEnvironment: typeof setBaseEnvironment
  closeListLayoutItems: typeof closeListLayoutItems
  openListLayoutItem: typeof openListLayoutItem
  openMonthlyCalendarPopup: typeof openMonthlyCalendarPopup
  closeMonthlyCalendarPopup: typeof closeMonthlyCalendarPopup
  openMonthlyCalendarEvent: typeof openMonthlyCalendarEvent
  closeMonthlyCalendarEvent: typeof closeMonthlyCalendarEvent
  appLoaded: () => void
  widgetLoaded: typeof widgetLoaded
  openMembersModal: typeof openMembersModal
}

export interface State extends CommonState {
  events: ExtendedEvent[]
  component: ComponentConfig
  translation: {[key: string]: string}
  demoEvents: DemoEvents
  layout: Layout
  calendarLayout: CalendarLayout
  membersEnabled: boolean
}

export interface DemoEvents {
  1: wix.events.Event[]
  2: wix.events.Event[]
}

export interface Layout {
  listItemOpened: string
  forceShowRibbons: boolean
}

export interface CalendarLayout {
  referenceDate: Date
  borderWidth: number
  monthly: MonthlyCalendarLayout
}

export interface MonthlyCalendarLayout {
  selectedDate: Date
  selectedEventId: string
}

export interface ComponentConfig {
  settings: ComponentSettings
  params: StyleParams
  byStatus?: wix.events.editor.ByStatus
  byEventId?: wix.events.editor.ByEventId
  id: string
}

export interface ComponentSettings {
  widgetType: 1 | 2
  listLayout: number
  widgetLayout: number
  alignment: number
  contentAlignment: number
  listAlignment: number
  imagePosition: number
  verticalImagePosition: number
  imageGridPosition: number
  imageScaling: number
  imageRatio: number
  listImageLayout: number
  showImage: boolean
  cardsPerRow: number
  showCountdown: boolean
  showTitle: boolean
  showDescription: boolean
  showDate: boolean
  showLocation: boolean
  showShareOptions: boolean
  showFullDividers: boolean
  showHorizontalDividers: boolean
  showCompactDividers: boolean
  showLabels: boolean
  showRSVPTitle: boolean
  showMembers: boolean
  listShowMainTitle: boolean
  listShowDate: boolean
  listShowDayOfWeek: boolean
  listShowVenueName: boolean
  listShowFullDate: boolean
  listShowLocation: boolean
  listShowDescription: boolean
  listShowSocialShare: boolean
  listShowImage: boolean
  listShowAdditionalInfo: boolean
  listShowMembers: boolean
  locationAndDateFormat: number
  buttonsStyle: number
  borderWidth: number
  buttonsBorderWidth: number
  buttonsBorderRadius: number
  roundedButtonsBorderRadius: number
  buttonHeight: number
  oneButtonWidth: number
  verticalDividerWidth: number
  verticalDividerHeight: number
  horizontalDividerWidth: number
  horizontalDividerHeight: number
  compactHorizontalDividerWidth: number
  compactHorizontalDividerHeight: number
  countdownMarginBottom: number
  titleMarginBottom: number
  descriptionMarginBottom: number
  dateMarginBottom: number
  locationMarginBottom: number
  dateAndLocationMarginBottom: number
  rsvpMarginBottom: number
  imageWidth: number
  imageOpacity: number
  listButtonStyle: ButtonStyle
  cardMinWidth: number
  cardMargins: number
  cardBorderWidth: number
  sideBySideLayoutMargins: number
  listImagePosition: number
  listImageOpacity: number
  listImageWidth: number
  listLocationAndDateFormat: number
  listDividerWidth: number
  listStripWidth: number
  texts: ComponentSettingsTexts
  calendarWeekStartDay: WEEKDAY
  todayButtonStyle: ButtonStyle
  calendarCellBorderWidth: number
  membersEnabled: boolean
  showRibbon: boolean
  responsive: boolean
}

export enum ButtonStyle {
  FULL = 1,
  HOLLOW = 2,
  FULL_ROUNDED = 3,
  HOLLOW_ROUNDED = 4,
}

export interface StyleParams {
  [key: string]: number | boolean
}

export interface ComponentSettingsTexts {
  time: string
  location: string
  RSVPTitle: string
  registrationClosedButtonText: string
  oneButton: string
  listButtonText: string
  listGeneralTitle: string
  todayButtonText: string
}

export type GetState = () => State

export interface StoreExtraArgs {
  wixCodeApi: IWixAPI
  compId: string
  baseUrl: string
}
