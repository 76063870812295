import * as React from 'react'
import {RibbonProps} from '.'
import * as s from './ribbon.scss'

export const Ribbon = ({text, t}: RibbonProps) =>
  text ? (
    <div className={s.root} data-hook="ribbon">
      <div className={s.text}>{t(text)}</div>
    </div>
  ) : null
