import {getFullLocale} from '../../../../commons/selectors/environment'
import {shouldShowRibbon} from '../../../selectors/events'
import {
  isAdditionalComponentsHidden,
  isDateVisible,
  isDescriptionVisible,
  isFullDateVisible,
  isImageVisible,
  isLocationVisible,
  isMembersEnabled,
  isMembersVisible,
  isSocialShareVisible,
  isVenueVisible,
} from '../../../selectors/list-settings'
import {getImageRatio} from '../../../selectors/settings'
import {AppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {Card as CardPresentation} from './card'
import {CardOwnProps, CardStateProps} from './interfaces'

const mapRuntime = ({state, host: {dimensions}, actions}: AppProps, {event}: CardOwnProps): CardStateProps => ({
  showImage: isImageVisible(state),
  showDate: isDateVisible(state),
  showVenue: isVenueVisible(state, dimensions),
  showFullDate: isFullDateVisible(state),
  showLocation: isLocationVisible(state),
  showDescription: isDescriptionVisible(state),
  showSocialBar: isSocialShareVisible(state),
  showMembers: isMembersVisible(state, event),
  membersEnabled: isMembersEnabled(state),
  hideAdditionalComponents: isAdditionalComponentsHidden(state),
  imageRatio: getImageRatio(state),
  imageOpacity: state.component.settings.listImageOpacity,
  alignment: state.component.settings.listAlignment,
  fullLocale: getFullLocale(state),
  hasRibbon: shouldShowRibbon(state, event),
})

export const Card = connect<CardOwnProps, CardStateProps>(mapRuntime)(CardPresentation)
export * from './interfaces'
