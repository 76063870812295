import {getWeekDayNames, WEEKDAY} from '@wix/wix-events-commons-statics/dist/date/date'
import {withNamespaces, WithNamespaces} from 'react-i18next'
import {getFullLocale} from '../../../../../commons/selectors/environment'
import {
  getCalendarWeeksWithEvents,
  getReferenceDate,
  CalendarWeeksWithEvents,
} from '../../../../selectors/calendar-layout'
import {getBorderStyle} from '../../../../utils/calendar'
import {AppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/connect'
import {MonthlyCalendarGrid as CalendarGridPresentation} from './monthly-calendar-grid'

export interface MonthlyCalendarGridOwnProps {}

export interface MonthlyCalendarGridRuntimeProps {
  calendarWeeks: CalendarWeeksWithEvents
  weekdayNames: WEEKDAY[]
  borderStyle: React.CSSProperties
}

export interface MonthlyCalendarGridProps
  extends MonthlyCalendarGridRuntimeProps,
    MonthlyCalendarGridOwnProps,
    WithNamespaces {}

export interface MonthlyCalendarCellRefs {
  [week: number]: MonthlyCalendarWeekCellRefs
}

export interface MonthlyCalendarWeekCellRefs {
  [weekDay: number]: HTMLElement
}

export interface MonthlyCalendarCellSizes {
  [week: number]: MonthlyCalendarWeekCellSizes
}

export interface MonthlyCalendarWeekCellSizes {
  [weekDay: number]: MonthlyCalendarCellSize
}

export interface MonthlyCalendarCellSize {
  width: number
  height: number
}

const mapRuntime = (context: AppProps): MonthlyCalendarGridRuntimeProps => ({
  calendarWeeks: getCalendarWeeksWithEvents(
    context,
    getReferenceDate(context.state),
    context.state.component.settings.calendarWeekStartDay,
  ),
  weekdayNames: getWeekDayNames(getFullLocale(context.state), context.state.component.settings.calendarWeekStartDay),
  borderStyle: getBorderStyle(context),
})

export const MonthlyCalendarGrid = connect<MonthlyCalendarGridOwnProps, MonthlyCalendarGridRuntimeProps>(mapRuntime)(
  withNamespaces()(CalendarGridPresentation),
)
