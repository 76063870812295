import {withNamespaces} from 'react-i18next'
import {isEditor, isFullWidth, isSite} from '../../../commons/selectors/environment'
import {getEvents, getFirstEvent} from '../../selectors/events'
import {isItemOpened} from '../../selectors/layout'
import {isAdditionalComponentsHidden, isImageVisible, isMembersEnabled} from '../../selectors/list-settings'
import {getImageRatio} from '../../selectors/settings'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {Cards as CardsPresentation} from './cards'
import {CardsOwnProps, CardsStateProps} from './interfaces'

const mapRuntimeToProps = ({state, host: {dimensions}}: AppProps): CardsStateProps => ({
  events: getEvents(state),
  showImage: isImageVisible(state),
  showMembers: isMembersEnabled(state),
  hideAdditionalComponents: isAdditionalComponentsHidden(state),
  columns: state.component.settings.cardsPerRow,
  cardMargins: state.component.settings.cardMargins,
  cardBorderWidth: state.component.settings.cardBorderWidth,
  imageRatio: getImageRatio(state),
  expand: isItemOpened(state, getFirstEvent(state).id),
  editor: isEditor(state),
  site: isSite(state),
  fullWidth: isFullWidth(state, dimensions),
})

export const Cards = connect<CardsOwnProps, CardsStateProps>(mapRuntimeToProps)(withNamespaces()(CardsPresentation))
export * from './interfaces'
